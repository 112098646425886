.title_description {
  color: black;
  display: flex;
  justify-content: space-between;
}


.column {
  float: left;
  width: 50%;
}

.card_container {
  margin-top: 25vw;
}

.table_header {
  background-color: #0066cc;
  color: white;
  font-size: 20px;
}

tr {
  border-bottom: 2px solid #0066cc;
}

th{
  padding-left: 2vw;
  text-align: center;
  padding: 1.65vw;
}

td{
  text-align: left;
  padding: 0.2vw;
  align-self:left;
    
  
}

tbody>tr>td:nth-child(1){

 /*  width: 500px; 
  height: 80px; */
  display: flex;
  align-items: center;
 }

 tbody>tr > td:nth-child(1) img{
float: left;
 }

tbody>tr>:nth-child(2){

  text-align:center;
  
 }

 tbody>tr>:nth-child(3){

  text-align:center;
 }
 

.blueTable{
  border-collapse: collapse;
}

.table_background {
  background-color: white;
  margin: 60px;
  padding: 20px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
  display: flex;
justify-content: center;

}

.chart_background {
  background-color: white;
  padding: 300px;
  padding-left: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pointer-executive:hover {
  cursor: pointer;
}

.iconDetails {
  float: center;
  height: 20px;
  width: 30px;
}

.used-budget {
  font-size: 18px;
  background: -webkit-linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(1, 98, 189, 1) 20%,
      rgba(21, 156, 182, 1) 92%,
      rgba(28, 220, 194, 1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-executive h4 {
  font-size: 20px;
  text-align: left;
}

.profile-image {
  height: 120px;
  width: 120px;
  margin-left: -20px;
  margin-top: -30px;
}

.pm-entity-image {
  height: 80px;
  width: 80px;
  margin-left: 5px;
  margin-top: -5px;
}